exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-social-list-js": () => import("./../../../src/templates/social-list.js" /* webpackChunkName: "component---src-templates-social-list-js" */),
  "component---src-templates-teacher-list-js": () => import("./../../../src/templates/teacher-list.js" /* webpackChunkName: "component---src-templates-teacher-list-js" */),
  "component---src-templates-teacher-page-js": () => import("./../../../src/templates/teacher-page.js" /* webpackChunkName: "component---src-templates-teacher-page-js" */)
}

